import React from 'react';
import "./Contact.css";

const Map = () => {
  return (
    <div className='map-content'>
        {/* eslint-disable-next-line */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2631.164361905537!2d9.299060576664543!3d48.740557008991516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799c1365d4dd821%3A0x1ba585391fba2024!2sBerliner%20Str.%204%2C%2073728%20Esslingen%20am%20Neckar!5e0!3m2!1sde!2sde!4v1687451529487!5m2!1sde!2sde" width="100%" height="450" style={{border:0}}></iframe>
    </div>
  )
}

export default Map
